export default {
  INVOICE_CREATE: "/SieuTriNhoHocDuong/InvoiceCreate",
  INVOICE_INFO: "/SieuTriNhoHocDuong/InvoiceInfo",
  INVOICE_UPDATE: "/SieuTriNhoHocDuong/InvoiceUpdate",
  GET_ONEPAY_RESPONSE_INFO: "/vi-VN/SieuTriNhoHocDuong/ResponeOnepay",
  GET_VNPAY_RESPONSE_INFO: "/vi-VN/SieuTriNhoHocDuong/ResponeVNPay",
  GET_MOMO_RESPONSE_INFO: "/vi-VN/SieuTriNhoHocDuong/ResponeMoMo",
  GET_ZALOPAY_RESPONSE_INFO: "/vi-VN/SieuTriNhoHocDuong/ResponeZaloPay",
  GET_VIETTELPAY_RESPONSE_INFO: "/vi-VN/SieuTriNhoHocDuong/ResponeViettelPay",
  UPDATE_INVOICE: "/SieuTriNhoHocDuong/InvoiceUpdate",
  GET_NGANLUONG_RESPONSE: "/SieuTriNhoHocDuong/ResponeNganLuong",
  GET_LIST_GROUPCODE: "/Options/list/groupcode",

  GET_LIST_BANK_NL: "/MstBank/list-bank-nl",

};
