import API from "../../configs/api";
import apiMethod from "../../../../utility/ApiMethodV2";

export const getListService = ({ codeLanguage, payload }) => {
  return apiMethod.get(codeLanguage + API.GET_BANNER, {
    params: {
      bannerType: payload,
    },
  });
};
