import API from "../../configs/api";
import apiMethod from "../../../../utility/ApiMethodV2";

export const formPaymentCallMethod = ({ payload, codeLanguage }) => {
  const {paymentMethod, bankCodeNL } = payload

  return apiMethod.post(codeLanguage + API.INVOICE_CREATE, {
    userId: parseInt(payload.userId),
    customerName: payload.customerName,
    customerEmail: payload.email,
    customerPhone: payload.phone,
    cityId: parseInt(payload.city),
    districtId: parseInt(payload.district),
    address: payload.address,
    itemCode: payload.itemCode,
    totalAmount: parseInt(payload.totalAmount),
    cardList: payload.cardList,
    code: payload?.code,
    urlResponseOnepay: payload?.urlResponseOnepay,
    paymentMethod,
    bankCodeNL,
  });
};

export const formPaymentUpdateMethod = ({ payload, codeLanguage }) => {
  return apiMethod.post(codeLanguage + API.INVOICE_UPDATE, {
    code: payload.code,
    statusCode: payload.statusCode,
  });
};
