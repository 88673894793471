import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Referral",
  dir: "Referral",
  pathRoot: "referral",
  routes: [
    {
      url: ":inviteId",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
